import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeShelters = () => {
  return (
    <Layout>
      <Seo
        title="Marquesinas y Recintos Cerrados para Bicicletas - BIKESAFE"
        description="Las marquesinas y recintos cerrados para bicicletas de Bike Safe ofrecen soluciones modernas, seguras y prácticas para satisfacer la creciente demanda de estacionamiento de bicicletas en espacios públicos y privados."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
              Marquesinas y Recintos Cerrados para Bicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
              Los días en que había que dejar la bicicleta atada a un árbol o a
              una farola en la calle, esperando que no fuera víctima de robos,
              han quedado atrás. Las marquesinas y recintos cerrados para
              bicicletas de Bike Safe se pueden instalar en la mayoría de las
              zonas públicas y privadas, satisfaciendo la creciente necesidad de
              espacios seguros para los ciclistas.
            </p>
            <p className="flow-text">
              Nuestra amplia gama de marquesinas para bicicletas es resistente,
              moderna y altamente funcional. Independientemente de las
              dimensiones o el estilo que necesite, tenemos la solución adecuada
              para proteger las bicicletas de robos y las inclemencias del
              tiempo.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/233">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/233/homepage-233.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Marquesina Gijón</h3>
              <p>
                Disponible en acabado galvanizado o revestimiento en color RAL,
                la marquesina Gijón incluye un soporte para bicicletas tipo
                toastrack galvanizado. Con techo y estructura resistentes que...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/38">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/38/homepage-38.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Marquesina Ark</h3>
              <p>
                Diseñada para maximizar la capacidad de almacenamiento, la
                marquesina Ark permite guardar bicicletas en dos filas,
                optimizando el espacio disponible. Disponible con diversas
                opciones de...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/22">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/22/homepage-22.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Marquesina Bristol</h3>
              <p>
                Equipada con características versátiles, la marquesina Bristol
                se adapta a cualquier entorno. Construida en acero galvanizado y
                con fijaciones robustas, ofrece una solución resistente y
                duradera...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BikeShelters;
